import * as React from "react"
import styled from 'styled-components';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

//Components
import ProjectInfo from '../../components/ProjectInfo';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectCaseStudy from '../../components/ProjectCaseStudy';
import ProjectSubHeaderTwo from "../../components/ProjectSubHeaderTwo";
import FooterWithPadding from '../../components/FooterWithPadding';

const BuoyPage = () => {
  const data = useStaticQuery(graphql`
    query {

      buoyShowcase: file(relativePath: { eq: "buoy/buoy-showcase.png" }) {
        childImageSharp {
          fluid(maxWidth: 1862) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
    }
  `)



  return (
    <Grid>
      <ProjectHeader 
        title="Buoy"
        subTitle="Floating window app for macOS"
      />
      <ProjectInfo 
        when="2020-2021"
        platform="Desktop"
        team="Me & 1 Fullstack Dev"
        role="Creator, Designer, Developer"
        tools="Figma, Electron, Gatsby JS, React, Styled Components"

        symbolPlatform="💻"
      />


      <ProjectCaseStudy 
        backgroundTitle="Background"
        backgroundText="Buoy is a floating browser window for multitasking and productivity. It allows you to keep content
        afloat so you can focus on the work in front of you without having to rely on the (cmd + tab) or (ctrl + tab) we are all 
        so used to. I starting building this during the pandemic for students and remote workers to replace the popular sunset app
        Helium. Buoy is currently in Beta while the bugs get squashed. The main reason I have chosen to put this on my 
        portfolio is to showcase the marketing landing page I built, which you can view below."
        
      />

      <ProjectSubHeaderTwo 
        title="Showcase"
        button="Download App"
        href="https://patternworksdesign.gumroad.com/l/gOOft"
        button2="View Landing Page"
        href2=" https://buoyapp.xyz/"
        
      />

      <ProjectShowcaseCard>
        <Image fluid={data.buoyShowcase.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <FooterWithPadding />
    </Grid>
  )
}

export default BuoyPage

const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  max-width: 1110px;
  margin: 0 auto;

`

const ProjectShowcaseCard = styled.div`
  padding: 48px;
  margin-top: 24px;

  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1rem;
  background: rgba(221, 221, 221, 0.11);

  @media (max-width: 900px) {
    padding: 24px;
  }
`

const Image = styled(Img)`
  max-width: 1682px;
  margin: 0 auto;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.16));
  border-radius: .5rem;
`


